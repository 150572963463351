// config.js
const CONFIG = {
    BASE_URL: 'https://cryptolama.online/',
    TMA_URL: 'https://t.me/cryptolamaz_bot/start'
};

export default CONFIG;
//https://cryptolama.online/
//https://t.me/DevdevdevClickerBot/start
//https://t.me/cryptolamaz_bot/start

//https://1181a98c5ad7.ngrok.app/telegram/
//https://t.me/lamalamalamatestbot/start